@font-face {
  font-family: 'Sunborn';
  src: url('./Sunborn-SansOne.otf');
}

@font-face {
  font-family: 'Intro Rust';
  src: url('./IntroRustG-Base2Line.otf');
}

html,
body {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: clamp(3rem, 2.127rem + 2.324vi, 4.75rem);
  font-family: 'Sunborn';
  margin: 2rem 0;
  color: #ED1D33;
}

.app {
  text-align: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.flavor-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  box-sizing: border-box;
  padding-left: 2rem;
  gap: 2rem 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: left;
}

.center {
  text-align: center;
}

@media (max-width: 756px) {
  .grid {
    grid-template-columns: minmax(100px, 1fr);
  }
}

.flex {
  display: flex;
}

.flavor-input {
  border: 1px solid black;
  width: 100%;
  padding: 1rem 1rem;
  border-radius: 0.5rem;
  margin-right: 2rem;
  font-size: 1.25rem;
}

input:focus {
  outline: none;
  border: 1px solid blue;
}

.flavor-item {
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: clamp(1.75rem, 1.9rem + 1.75vi, 4rem);
  font-weight: 800;
  color: #2D3590;
}

.flavor-item .flavor-text {
  font-family: 'Intro Rust', sans-serif;
}

.flavor-item span {
  padding-right: 1rem;
}

form {
  flex-direction: column;
  align-items: center;
  position: relative;
}

.input-wrapper {
  display: flex;
  width: 80%;
  margin: 0 auto;
  margin-top: 2rem;
  align-items: center;
}

.button-56 {
  align-items: center;
  background-color: #351cc1;
  border: 2px solid transparent;
  border-radius: 8px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: flex;
  font-family: Inter, sans-serif;
  font-size: 16px;
  height: 48px;
  justify-content: center;
  line-height: 24px;
  max-width: 100%;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-56:after {
  background-color: #a1b5ee;
  border-radius: 8px;
  content: "";
  display: block;
  height: 48px;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(8px, 8px);
  transition: transform .2s ease-out;
  z-index: -1;
}

.button-56:hover:after {
  transform: translate(0, 0);
}

.button-56:active {
  background-color: #ffdeda;
  outline: 0;
}

.button-56:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .button-56 {
    padding: 0 40px;
  }
}

.default-btn {
  margin: 0 auto;
}

.gradient {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  background: linear-gradient(-45deg, #ff7e7e, #7293ff, #ff7a7a, #8387ff);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.options {
  margin-top: 6rem;
  border: 1px solid black;
  border-radius: 10px;
  padding: 1.5rem;
}

.options h3 {
  margin: 0;
  margin-bottom: 1rem;
}

.option {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: center;
  margin-top: 0.5rem;
}